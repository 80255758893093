import { OverlayRenderer } from './overlay-canvas-renderer';
import { initStore, MetaData, OverlayGeneratorChunkData } from './store';
import { preloadData } from './store/utils/preloadData';
import { Coordinates, Events, Homography, Size } from './types';

import { LogEvent, Time } from './utils/decorators';
import { getChunkNumberFromFrameAndChunkSize } from './utils/getChunkNumberFromFrameAndChunkSize';
import { getVideoScale } from './utils/getVideoScale';
import { loadChunk, loadMetaData, OverlayElementsMetaData, RecordingOverlayData } from './utils/loaders';
import { scaleMatrix } from './utils/scaleMatrix';
import { transformHomographyIntoMatrix3d } from './utils/transformHomographyIntoMatrix3d';

export const offensiveTactics = [
  'accompany-play-team-together',
  'balance-of-the-team-after-recovery',
  'cross-into-the-box',
  'finishing',
  'finishing-after-cross',
  'finishing-pass',
  'goal',
  'goal-assist',
  'goal-chance',
  'goal-kick-start-long-inside-channels',
  'goal-kick-start-long-outside-channels',
  'goal-kick-start-short-inside-channels',
  'goal-kick-start-short-outside-channels',
  'identifying-passing-lines-under-pressure',
  'long-ball',
  'lost-ball',
  'moving-behind-the-defensive-line',
  'occupying-space-in-the-box',
  'open-passing-lines-after-long-ball',
  'overcoming-opponents-with-vertical-passes',
  'passing-between-lines',
  'pass-behind-defensive-line',
  'positioning-behind-center-backs-when-lateral-balls',
  'possession-after-recovery',
  'progression-after-recovery',
  'realized-emergency-support',
  'realized-finishing-support',
  'realized-horizontal-overcoming-support',
  'realized-striker-support',
  'realized-vertical-overcoming-support',
  'receive-foul-after-recovery',
  'receiving-between-lines',
  'receiving-positioning-between-lines',
  'running-into-the-box',
  'second-ball-offensive-winning-after-cross',
  'second-ball-offensive-winning-after-direct-play',
  'second-ball-offensive-winning-after-finishing',
  'second-ball-offensive-winning-after-set-piece',
  'space-between-defensive-line-and-halfway-line',
  'supports',
  'switch-of-play',
  'taking-advantage-of-defensive-line-imbalances',
  'width-of-the-team',
  'width-of-the-team-opposite-channel',
] as const;

export const defensiveTactics = [
  'balance-of-the-team',
  'balance-of-the-team-after-loss',
  'clear-the-box',
  'commit-foul-after-loss',
  'compactness-of-team',
  'defending-against-the-possessor',
  'defending-moving-behind-the-defensive-line',
  'defending-running-into-the-box',
  'defensive-line-imbalance-in-depth',
  'defensive-line-imbalance-in-width',
  'hold-after-loss',
  'marking-opponents-inside-the-box',
  'marking-supports',
  'moving-forward-during-organized-pressure',
  'neutralizing-opponent-advantage-of-defensive-line-imbalance',
  'press-after-loss',
  'pressure-on-the-ball-possessor',
  'recovered-ball',
  'second-ball-defensive-winning-after-cross',
  'second-ball-defensive-winning-after-direct-play',
  'second-ball-defensive-winning-after-finishing',
  'second-ball-defensive-winning-after-set-piece',
  'tackle',
] as const;

export type OffensiveTacticId = (typeof offensiveTactics)[number];
export type DefensiveTacticId = (typeof defensiveTactics)[number];

export type TacticId = OffensiveTacticId | DefensiveTacticId;

export type PlayersPosition = { [key in string]: Coordinates };
export type PlayersPositions = { [key in string]: PlayersPosition };
export type Homographies = { [key in number]: Homography };
export type TimeSeries = { [key in number]: Array<number> };

export enum Quality {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  VERY_HIGH = 'veryHigh',
}

export const INITIAL_QUALITY = Quality.VERY_HIGH;

export const QUALITY_TO_SCALE_FACTOR = {
  [Quality.LOW]: 8,
  [Quality.MEDIUM]: 10,
  [Quality.HIGH]: 12,
  [Quality.VERY_HIGH]: 16,
};

export const IDENTIFY_TRANSFORMATION_MATRIX = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0];
const CHUNK_SIZE = 3750;

export interface OverlayGeneratorConfig {
  domainUrl?: string;
  headers?: HeadersInit;
  imageInterface: unknown;
  fetchInterface: unknown;
  useContainer?: boolean;
  recordingData?: RecordingOverlayData | undefined;
  overlayElementsMetaData?: OverlayElementsMetaData | undefined;
}

export class OverlayGenerator {
  overlayRendered: OverlayRenderer;
  store = initStore();
  config: OverlayGeneratorConfig;
  overlayElementsMetaData?: OverlayElementsMetaData | undefined;

  constructor(config: OverlayGeneratorConfig) {
    this.config = config;
    this.overlayRendered = new OverlayRenderer({
      imageInterface: this.config.imageInterface,
      useContainer: this.config.useContainer ?? true,
    });
  }

  @LogEvent({ type: Events.INIT })
  async init({ tacticalAnalysisId, recordingId }: { tacticalAnalysisId: string; recordingId: string }) {
    if (this.getTacticalAnalysisId() !== tacticalAnalysisId || this.getRecordingId() !== recordingId) {
      this.overlayRendered = new OverlayRenderer({
        imageInterface: this.config.imageInterface,
        useContainer: this.config.useContainer ?? true,
      });
      this.store.actions.setTacticalAnalysisId(tacticalAnalysisId);
      this.store.actions.setRecordingId(recordingId);
    }

    return await this.loadMetadata();
  }

  @Time()
  @LogEvent({ type: Events.DRAW_FRAME_IN_CANVAS })
  async drawFrameInCanvas(
    container: HTMLDivElement,
    frame: number,
    options: {
      tactics?: TacticId[];
    },
    shouldUseObfuscationEndpoint: boolean,
  ) {
    if (this.store.store.getState().status.isLoadingData) return false;
    const currentFrameChunkNumber = this.getChunkNumberFromFrame(frame);
    const isCurrentFrameChunkLoaded = Object.keys(this.getChunksData()).includes(currentFrameChunkNumber.toString());
    const shouldPreloadNextChunk = preloadData(
      this.getChunksData(),
      currentFrameChunkNumber,
      frame,
      this.getChunkSize(),
    );

    if (!isCurrentFrameChunkLoaded && !this.store.store.getState().status.isLoadingData) {
      // TODO How to handle when trajectories are not available?
      await this.load(shouldUseObfuscationEndpoint, currentFrameChunkNumber);
      this.store.actions.validateChunkDataMemory(currentFrameChunkNumber);
    }

    if (shouldPreloadNextChunk && !this.store.store.getState().status.isLoadingAsyncData) {
      this.load(shouldUseObfuscationEndpoint, currentFrameChunkNumber + 1, true);
    }

    const metaData = this.getMetaData();
    const frameInfo = this.overlayRendered.renderFrame({
      frame,
      playersPositions: this.getTrajectories(currentFrameChunkNumber),
      scale: this.getRenderScale(),
      overlayTactics: this.getOverlayTactics(currentFrameChunkNumber),
      filters: { tactics: options.tactics },
      pitchSize: metaData.pitch.originalSize,
      teams: metaData.teams,
      container,
    });

    this.store.actions.setFrameInfo(frameInfo);
  }

  getHomography(frame: number): Homography | undefined {
    const chunkNumber = this.getChunkNumberFromFrame(frame);
    const homographies = this.getHomographies(chunkNumber);
    return homographies[frame];
  }

  getTransformationMatrix(frame: number, videoSourceSize: Size): number[] | undefined {
    const metaData = this.getMetaData();
    const videoScale = getVideoScale(metaData.video.width, videoSourceSize.width);

    return this.generateTransformationMatrix(frame, videoScale);
  }

  @LogEvent({ type: Events.SET_RECORDING_DATA })
  reset() {
    this.config.recordingData = undefined;
    this.config.overlayElementsMetaData = undefined;
    this.store.actions.reset();
  }

  @LogEvent({ type: Events.SET_RECORDING_DATA })
  setRecordingData(recordingData?: RecordingOverlayData | undefined) {
    if (!recordingData) return;
    this.config.recordingData = recordingData;
  }

  @LogEvent({ type: Events.OVERLAY_ELEMENTS_META_DATA })
  setOverlayElementsMetaData(overlayElementsMetaData?: OverlayElementsMetaData | undefined) {
    if (!overlayElementsMetaData) return;
    this.config.overlayElementsMetaData = overlayElementsMetaData;
  }

  @LogEvent({ type: Events.CHANGE_QUALITY })
  setQuality(quality: Quality) {
    if (!quality) return;
    this.setRenderScale(QUALITY_TO_SCALE_FACTOR[quality]);
  }

  getMetaData() {
    return this.store.store.getState().metaData;
  }

  @LogEvent({ type: Events.CHANGE_RENDER_SCALE })
  setRenderScale(renderScale: number) {
    const metaData = this.getMetaData();
    const updatedMetadata = {
      ...metaData,
      pitch: {
        ...metaData.pitch,
        size: {
          width: metaData.pitch.originalSize.width * renderScale,
          length: metaData.pitch.originalSize.length * renderScale,
        },
      },
    };
    this.setMetaData(updatedMetadata);
    this.store.actions.setRenderScale(renderScale);
  }

  @LogEvent({ type: Events.LOAD_METADATA })
  private async loadMetadata() {
    const resultMetaData = this.config.overlayElementsMetaData
      ? this.config.overlayElementsMetaData
      : await loadMetaData({
          recordingId: this.getRecordingId(),
          domainUrl: this.config.domainUrl,
          headers: this.config.headers,
          fetchInterface: this.config.fetchInterface,
        });

    const metaData = {
      pitch: {
        size: {
          length: resultMetaData.pitchSize.length * this.getRenderScale(),
          width: resultMetaData.pitchSize.width * this.getRenderScale(),
        },
        originalSize: {
          length: resultMetaData.pitchSize.length,
          width: resultMetaData.pitchSize.width,
        },
      },
      teams: resultMetaData.teams,
      video: { ...resultMetaData.video, duration: 0 },
      chunkSize: CHUNK_SIZE,
    };

    this.setMetaData(metaData);
  }

  @LogEvent({ type: Events.LOAD })
  private async load(shouldUseObfuscationEndpoint: boolean, chunkNumber: number = 0, async: boolean = false) {
    if (!async) this.startDataLoading();
    if (async) this.startDataLoadingAsync();
    const startFrame = chunkNumber * this.getMetaData().chunkSize;
    const endFrame = startFrame + this.getMetaData().chunkSize;
    try {
      const { homographies, trajectories, overlayTactics } = this.config.recordingData
        ? this.config.recordingData
        : await loadChunk({
            domainUrl: this.config?.domainUrl,
            tacticalAnalysisId: this.getTacticalAnalysisId(),
            startFrame,
            endFrame,
            headers: this.config.headers,
            fetchInterface: this.config.fetchInterface,
            shouldUseObfuscationEndpoint: shouldUseObfuscationEndpoint,
          });

      this.store.actions.setChunkData(chunkNumber, {
        homographies,
        playersPositions: trajectories,
        overlayTactics,
      });
    } catch (e) {
      console.error(`Error loading chunk ${chunkNumber} - ${e}`);
      this.store.actions.setErrorChunkData(chunkNumber);
    }

    if (!async) this.endDataLoading();
    if (async) this.endDataLoadingAsync();
  }

  @LogEvent({ type: Events.START_LOADING_DATA })
  private startDataLoading() {
    this.store.actions.startLoadingData();
  }

  @LogEvent({ type: Events.START_LOADING_ASYNC_DATA })
  private startDataLoadingAsync() {
    this.store.actions.startLoadingAsyncData();
  }

  @LogEvent({ type: Events.END_LOADING_DATA })
  private endDataLoading() {
    this.store.actions.finishLoadingData();
  }

  @LogEvent({ type: Events.END_LOADING_ASYNC_DATA })
  private endDataLoadingAsync() {
    this.store.actions.finishLoadingAsyncData();
  }

  @LogEvent({ type: Events.UPDATE_METADATA })
  private setMetaData(metaData: MetaData) {
    this.store.actions.setMetaData(metaData);
  }

  private generateTransformationMatrix(frame: number, videoScale: number) {
    const homographies = this.getHomographies(this.getChunkNumberFromFrame(frame));

    if (!homographies || !homographies[frame]) {
      return;
    }

    const scaledMatrix = scaleMatrix(homographies[frame], this.getRenderScale(), videoScale);
    return transformHomographyIntoMatrix3d(scaledMatrix);
  }

  private getChunkNumberFromFrame = (frame: number) => {
    return getChunkNumberFromFrameAndChunkSize(frame, this.getChunkSize());
  };

  private getTacticalAnalysisId = () => {
    return this.store.store.getState().tacticalAnalysisId;
  };

  private getRecordingId = () => {
    return this.store.store.getState().recordingId;
  };

  private getChunksData = (): OverlayGeneratorChunkData => {
    return this.store.store.getState().chunkData;
  };

  private getChunkSize = () => {
    return this.store.store.getState().metaData.chunkSize;
  };

  private getRenderScale() {
    return this.store.store.getState().renderScale;
  }

  private getHomographies(chunkNumber: number) {
    return this.getChunksData()[chunkNumber]?.data?.homographies ?? [];
  }

  private getTrajectories(chunkNumber: number) {
    return this.getChunksData()[chunkNumber]?.data?.playersPositions ?? {};
  }

  private getOverlayTactics(chunkNumber: number) {
    return this.getChunksData()[chunkNumber]?.data?.overlayTactics ?? [];
  }
}
