const routesPrefixes = {
  BACKOFFICE: '/kognia-backoffice',
  TAGGING_TOOL: '/tagging-tool',
};

export const routes = {
  PERFORMANCE_REPORT_TIMELINE: '/performance-report/:id/timeline',
  RECORDING_PLAYLIST_DETAIL: '/recordings/playlists/:playlistId',
  RECORDING_PLAYLISTS: '/recordings/:id/playlists',
  RECORDING_EDIT: '/recordings/:id/edit',
  RECORDING_LIST: '/recordings',
  LIVE_TAGGING_SESSION_EDIT: '/live-tagging-sessions/:recordingId/edit',
  RECORDING_APP: '/recording-app',
  TAGGING_TOOL_TAG: `${routesPrefixes.TAGGING_TOOL}/:recordingId/tag`,
  TAGGING_TOOL: routesPrefixes.TAGGING_TOOL,
  TAGGING_TOOL_UPLOAD_STEP_ONE: `${routesPrefixes.TAGGING_TOOL}/upload`,
  TAGGING_TOOL_UPLOAD_STEP_TWO: `${routesPrefixes.TAGGING_TOOL}/upload/:recordingId`,
  TAGGING_TOOL_ALIGN_SOURCES: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align/sources`,
  TAGGING_TOOL_ALIGN_RECORDING: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align`,
  TAGGING_TOOL_ALIGN_RECORDING_WITH_SOURCE: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align/:sourceRecordingId`,
  TAGGING_TOOL_RECORDING: `${routesPrefixes.TAGGING_TOOL}/:recordingId`,
  PLAYLISTS: '/playlists',
  ACCOUNT: '/account',
  KEYPADS_EDIT: '/keypads/:id',
  KEYPADS: '/keypads',
  LOGIN: '/login',
  BACKOFFICE: routesPrefixes.BACKOFFICE,
  BACKOFFICE_ANNOTATION: `${routesPrefixes.BACKOFFICE}/annotation`,
  BACKOFFICE_PLAYERS: `${routesPrefixes.BACKOFFICE}/players`,
  BACKOFFICE_TEAMS: `${routesPrefixes.BACKOFFICE}/teams`,
  PROCESS_GAME: `${routesPrefixes.BACKOFFICE}/process-game`,
  BACKOFFICE_ADD_USER_TO_ENVIRONMENT: `${routesPrefixes.BACKOFFICE}/users/add-user-to-environment`,
  BACKOFFICE_CREATE_USER: `${routesPrefixes.BACKOFFICE}/users/create-user`,
  BACKOFFICE_ENVIRONMENTS: `${routesPrefixes.BACKOFFICE}/environments`,
  BACKOFFICE_CREATE_ENVIRONMENT: `${routesPrefixes.BACKOFFICE}/create-environment`,
  BACKOFFICE_ENVIRONMENT_DETAIL: `${routesPrefixes.BACKOFFICE}/environments/:id`,
  BACKOFFICE_EDIT_ENVIRONMENT: `${routesPrefixes.BACKOFFICE}/environments/:id/edit`,
  BACKOFFICE_CREATE_ENVIRONMENT_BRANDING: `${routesPrefixes.BACKOFFICE}/environments/:id/add-branding`,
  BACKOFFICE_EDIT_ENVIRONMENT_BRANDING: `${routesPrefixes.BACKOFFICE}/environments/:id/edit-branding`,
  BACKOFFICE_ASSOCIATE_COMPETITION_WITH_CLIENTS: `${routesPrefixes.BACKOFFICE}/associate-competition-with-clients`,
  BACKOFFICE_COMPETITIONS: `${routesPrefixes.BACKOFFICE}/competitions`,
  BACKOFFICE_COACHES: `${routesPrefixes.BACKOFFICE}/coaches`,
  BACKOFFICE_GAMES: `${routesPrefixes.BACKOFFICE}/games`,
  BACKOFFICE_EDIT_GAME_FORM: `${routesPrefixes.BACKOFFICE}/edit-game-form/:id`,
  BACKOFFICE_GAME_FORM: `${routesPrefixes.BACKOFFICE}/game-form/(home-team|opponent-team|game-info|summary)/:id?`,
  BACKOFFICE_GAME_SUMMARY: `${routesPrefixes.BACKOFFICE}/game-summary/:id`,
  BACKOFFICE_GAME_FORM_STEP_1: `${routesPrefixes.BACKOFFICE}/game-form/home-team/:id?`,
  BACKOFFICE_GAME_FORM_STEP_2: `${routesPrefixes.BACKOFFICE}/game-form/opponent-team/:id?`,
  BACKOFFICE_GAME_FORM_STEP_3: `${routesPrefixes.BACKOFFICE}/game-form/game-info/:id?`,
  BACKOFFICE_NEW_GAME_SUMMARY: `${routesPrefixes.BACKOFFICE}/game-form/summary/:id`,
  BACKOFFICE_VENUES: `${routesPrefixes.BACKOFFICE}/venues`,
  BACKOFFICE_SEASON_COMPETITIONS: `${routesPrefixes.BACKOFFICE}/season-competitions`,
  BACKOFFICE_STAGES: `${routesPrefixes.BACKOFFICE}/stages`,
  SUBMIT_FEEDBACK:
    'https://docs.google.com/forms/d/e/1FAIpQLSdIWFqMxFlxLmRNz4IVzrTmBYJHyOEhO_Ohmssph7-A510Mwg/viewform',
  HOME_PAGE: '/',
  DASHBOARD_PREVIEW: '/dashboard-preview',
};

const routesSectionsList = {
  HOME: 'home',
  TACTICAL_ANALYSIS: 'tacticalAnalysis',
  TAGGING: 'tagging',
  PLAYLISTS: 'playlists',
  KEYPADS: 'keypads',
  ACCOUNT: 'account',
  SUPPORT: 'support',
  BACKOFFICE_ANNOTATION: 'backoffice-annotation',
  BACKOFFICE_METADATA_GAMES: 'backoffice-metadata-games',
};

// assign route to a section to set active specific link (sidebar-menu)

export const routesSections = {
  [routes.PERFORMANCE_REPORT_TIMELINE]: routesSectionsList.TACTICAL_ANALYSIS,
  [routes.RECORDING_PLAYLIST_DETAIL]: routesSectionsList.PLAYLISTS,
  [routes.RECORDING_PLAYLISTS]: routesSectionsList.PLAYLISTS,
  [routes.RECORDING_LIST]: routesSectionsList.TACTICAL_ANALYSIS,
  [routes.RECORDING_EDIT]: routesSectionsList.TACTICAL_ANALYSIS,
  [routes.LIVE_TAGGING_SESSION_EDIT]: routesSectionsList.TAGGING,
  [routes.TAGGING_TOOL_TAG]: routesSectionsList.TAGGING,
  [routes.TAGGING_TOOL]: routesSectionsList.TAGGING,
  [routes.PLAYLISTS]: routesSectionsList.PLAYLISTS,
  [routes.ACCOUNT]: routesSectionsList.ACCOUNT,
  [routes.KEYPADS_EDIT]: routesSectionsList.KEYPADS,
  [routes.KEYPADS]: routesSectionsList.KEYPADS,
  [routes.HOME_PAGE]: routesSectionsList.HOME,
  [routes.BACKOFFICE_ANNOTATION]: routesSectionsList.BACKOFFICE_ANNOTATION,
  [routes.BACKOFFICE_GAMES]: routesSectionsList.BACKOFFICE_METADATA_GAMES,
  [routes.BACKOFFICE]: routesSectionsList.BACKOFFICE_METADATA_GAMES,
};

export const queryParams = {
  copyPlaylist: 'copyPlaylist',
};
