import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { SelectDashboardWidget } from 'widgets/dashboard/SelectDashboard.widget';

export const DashboardPreviewPage = () => {
  const showAppDashboards = useFeatureFlag(FEATURE_FLAG.APP_DASHBOARDS);

  if (!showAppDashboards) return null;
  return (
    <SidebarLayout>
      <Container fullScreen>
        <SelectDashboardWidget />
      </Container>
    </SidebarLayout>
  );
};
