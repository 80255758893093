import { Checkbox, Box, Stack, Tooltip } from '@mui/material';
import { Colors } from 'kognia-ui';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import IconBall from 'shared/components/icons/icon-ball';
import { IconNoBall } from 'shared/components/icons/icon-no-ball';
import { TeamLogo } from 'shared/components/team-logo';
import Toggle from 'shared/components/toggle';
import { VerticalSeparator } from 'shared/components/vertical-separator';

import { PlayingModeToggle } from './playing-mode-toggle';
import { usePinScenarios } from '../../../hooks/use-pin-scenarios';
import { useTacticalAnalysisEpisodes } from '../../../hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisTeams } from '../../../hooks/use-tactical-analysis-teams';
import { useTeamColors } from '../../../hooks/use-team-color';
import {
  useSetTimelineNoShowBallPossession,
  useSetTimelineShowBallPossession,
  useTimelineShowBallPossession,
  useTimelineShowNoBallPossession,
} from '../../../hooks/use-timeline-ball-possession';
import { useSetTimelineTeamIdFocus, useTimelineTeamIdFocus } from '../../../hooks/use-timeline-team-id-focus';
import { TIMELINE_CONFIG } from '../config';

const TIMELINE_TEAM_CONTROLS_HEIGHT = '80px';
const TIMELINE_TEAM_CONTROLS_NO_EPISODES_HEIGHT = '40px';

export const TimelineControlsWrapper = ({
  children,
  scenarios,
  recordingId,
}: PropsWithChildren<{
  recordingId: string;
  scenarios: React.ReactNode;
}>) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const pinScenarios = usePinScenarios();

  const hasEpisodes = episodes.length > 0;
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          minHeight: TIMELINE_TEAM_CONTROLS_HEIGHT,
          background: ({ palette }) => palette.common.white,
          transform: `translate3d(0, 0, 0)`,

          ...(!hasEpisodes && {
            minHeight: TIMELINE_TEAM_CONTROLS_NO_EPISODES_HEIGHT,
            height: TIMELINE_TEAM_CONTROLS_NO_EPISODES_HEIGHT,
          }),
        }}
      >
        {hasEpisodes ? children : null}
        {pinScenarios ? scenarios : null}
      </Box>
      {!pinScenarios ? scenarios : null}
    </>
  );
};

const TimelineControlsContent = ({
  children,
  recordingId,
}: PropsWithChildren<{
  recordingId: string;
}>) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);

  const hasEpisodes = episodes.length > 0;

  return (
    <Box
      sx={{
        minHeight: TIMELINE_TEAM_CONTROLS_HEIGHT,
        // TODO get from theme
        background: Colors.background,
        ...(!hasEpisodes && { minHeight: TIMELINE_TEAM_CONTROLS_NO_EPISODES_HEIGHT }),
      }}
    >
      {children}
    </Box>
  );
};

type Props = {
  recordingId: string;
};

export const TimelineControls = ({ recordingId }: Props) => {
  const teams = useTacticalAnalysisTeams(recordingId);
  const { isHomeTeamSelected, teamIdFocus } = useTimelineTeamIdFocus(recordingId);
  const { t } = useTranslation();
  const setTimelineTeamIdFocus = useSetTimelineTeamIdFocus(recordingId);
  const showBallPossession = useTimelineShowBallPossession(recordingId);
  const showNoBallPossession = useTimelineShowNoBallPossession(recordingId);
  const setShowBallPossession = useSetTimelineShowBallPossession(recordingId);
  const setShowNoBallPossession = useSetTimelineNoShowBallPossession(recordingId);
  const teamColor = useTeamColors();

  const handleToggle = useCallback(() => {
    if (!teams.opponentTeam?.id || !teams.homeTeam?.id) return;

    setTimelineTeamIdFocus(isHomeTeamSelected ? teams.opponentTeam.id : teams.homeTeam.id);
  }, [teams, isHomeTeamSelected, setTimelineTeamIdFocus]);

  const handleToggleShowBallPossession = useCallback(() => {
    setShowBallPossession(!showBallPossession);
  }, [setShowBallPossession, showBallPossession]);

  const handleToggleShowNoBallPossession = useCallback(() => {
    setShowNoBallPossession(!showNoBallPossession);
  }, [setShowNoBallPossession, showNoBallPossession]);

  if (!teams.homeTeam?.id || !teams.opponentTeam?.id) return null;

  return (
    <TimelineControlsContent recordingId={recordingId}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        style={{ height: `${TIMELINE_CONFIG.STICKY_HEADER_HEIGHT / 2}px` }}
      >
        <PlayingModeToggle />
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        style={{ height: `${TIMELINE_CONFIG.STICKY_HEADER_HEIGHT / 2}px` }}
      >
        <Stack direction={'row'} gap={1} px={1}>
          <TeamLogo src={teams.homeTeam?.logo} name={teams.homeTeam?.name} />
          <Tooltip enterDelay={500} placement='bottom' title={t('timeline:tooltips.change-team-focus')}>
            <Toggle
              checked={teamIdFocus === teams.opponentTeam.id}
              onClick={handleToggle}
              onColor={teamColor.opponent}
              offColor={teamColor.home}
            />
          </Tooltip>
          <TeamLogo src={teams.opponentTeam?.logo} name={teams.opponentTeam?.name} />
        </Stack>
        <VerticalSeparator height={'18px'} />
        <div style={{ flex: 1 }}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'left'}>
            <Tooltip
              enterDelay={500}
              placement='bottom'
              title={
                showBallPossession
                  ? t('timeline:tooltips.hide-ball-possession')
                  : t('timeline:tooltips.show-ball-possession')
              }
            >
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox
                  color={isHomeTeamSelected ? 'tertiary' : 'quaternary'}
                  checked={showBallPossession}
                  onChange={handleToggleShowBallPossession}
                  sx={{ color: isHomeTeamSelected ? 'tertiary.main' : 'quaternary.main' }}
                />
                <IconBall onClick={handleToggleShowBallPossession} />
              </Stack>
            </Tooltip>

            <Tooltip
              enterDelay={500}
              placement='bottom'
              title={
                showNoBallPossession
                  ? t('timeline:tooltips.hide-no-ball-possession')
                  : t('timeline:tooltips.show-no-ball-possession')
              }
            >
              <Stack direction={'row'} alignItems={'center'}>
                <CheckboxWithCustomColor
                  checked={showNoBallPossession}
                  onChange={handleToggleShowNoBallPossession}
                  customColor={isHomeTeamSelected ? 'tertiaryLight' : 'quaternaryLight'}
                />
                <IconNoBall onClick={handleToggleShowNoBallPossession} />
              </Stack>
            </Tooltip>
          </Stack>
        </div>
      </Stack>
    </TimelineControlsContent>
  );
};
