import { Alert, Box, Stack } from '@mui/material';
import { useRef } from 'react';

import { useEmbeddedDashboard } from './hooks/useEmbeddedDashboard';

type Props = {
  dashboardId: string;
};

export const EmbeddedDashboard = ({ dashboardId }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isError, isSuccess } = useEmbeddedDashboard(dashboardId, containerRef);

  if (isError) {
    return (
      <Box
        sx={{
          height: '100%',
          padding: 2,
        }}
      >
        <Alert severity={'error'}>Error loading dashboard</Alert>
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        visibility: isSuccess ? 'visible' : 'hidden',
        height: '100%',
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          flex: 1,
          overflow: 'hidden',
          iframe: { border: 0, width: '100%', height: '100%' },
        }}
      />
    </Stack>
  );
};
