import { Box, IconButton } from '@mui/material';
import React from 'react';

import { routes } from 'kognia/router/routes';
import IconBall from 'shared/components/icons/icon-ball';
import IconCamera from 'shared/components/icons/icon-camera';
import { IconDoubleArrowLeft } from 'shared/components/icons/icon-double-arrow-left';
import { IconDoubleArrowRight } from 'shared/components/icons/icon-double-arrow-right';
import { IconSystemAdmin } from 'shared/components/icons/icon-system-admin';
import { MenuItem, MenuItemType, SidebarMenu } from 'shared/components/sidebar-layout/sidebar-menu';
import { SidebarLayoutContent, SidebarLayoutWrapper } from 'shared/components/sidebar-layout/SideBarLayout.styled';
import { useSidebar } from 'shared/hooks/use-sidebar';

import { BackofficeSidebarLayoutSidebar, BackofficeSidebarLetterIcon } from './index.styled';
import { useBackofficeSidebar } from '../../hooks/use-backoffice-sidebar';

interface Props {
  children: React.ReactNode;
}

export const BackofficeSidebarLayout = ({ children }: Props) => {
  const { isBackofficeSidebarOpen, toggleBackofficeSidebar } = useBackofficeSidebar();
  const { isSidebarOpen } = useSidebar();

  const primaryMenuItems: MenuItem[] = [
    {
      label: 'Sports metadata',
      icon: <IconBall size='small' />,
      type: MenuItemType.TITLE,
    },
    {
      label: 'Games',
      link: routes.BACKOFFICE_GAMES,
      icon: <BackofficeSidebarLetterIcon>G</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },

    {
      label: 'Players',
      link: routes.BACKOFFICE_PLAYERS,
      icon: <BackofficeSidebarLetterIcon>P</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Coaches',
      link: routes.BACKOFFICE_COACHES,
      icon: <BackofficeSidebarLetterIcon>C</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Teams',
      link: routes.BACKOFFICE_TEAMS,
      icon: <BackofficeSidebarLetterIcon>T</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Competitions',
      link: routes.BACKOFFICE_COMPETITIONS,
      icon: <BackofficeSidebarLetterIcon>CP</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Stages',
      link: routes.BACKOFFICE_STAGES,
      icon: <BackofficeSidebarLetterIcon>S</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Season competitions',
      link: routes.BACKOFFICE_SEASON_COMPETITIONS,
      icon: <BackofficeSidebarLetterIcon>SC</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Venues',
      link: routes.BACKOFFICE_VENUES,
      icon: <BackofficeSidebarLetterIcon>V</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Operations',
      icon: <IconCamera size='small' />,
      type: MenuItemType.TITLE,
    },
    {
      label: 'Annotation',
      link: routes.BACKOFFICE_ANNOTATION,
      icon: <BackofficeSidebarLetterIcon>A</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Process game',
      link: routes.PROCESS_GAME,
      icon: <BackofficeSidebarLetterIcon>PG</BackofficeSidebarLetterIcon>,
      type: MenuItemType.LINK,
    },
    {
      label: 'Administration',
      icon: <IconSystemAdmin size='small' />,
      type: MenuItemType.TITLE,
    },
    {
      label: 'Environments',
      type: MenuItemType.PARENT,
      icon: <BackofficeSidebarLetterIcon>E</BackofficeSidebarLetterIcon>,
      items: [
        {
          label: 'Environments',
          link: routes.BACKOFFICE_ENVIRONMENTS,
          type: MenuItemType.LINK,
        },
        {
          label: 'Create environment',
          link: routes.BACKOFFICE_CREATE_ENVIRONMENT,
          type: MenuItemType.LINK,
        },
        {
          label: 'Associate competition with client(s)',
          link: routes.BACKOFFICE_ASSOCIATE_COMPETITION_WITH_CLIENTS,
          type: MenuItemType.LINK,
        },
      ],
    },
    {
      label: 'Users',
      type: MenuItemType.PARENT,
      icon: <BackofficeSidebarLetterIcon>U</BackofficeSidebarLetterIcon>,
      items: [
        {
          label: 'Create user',
          link: routes.BACKOFFICE_CREATE_USER,
          type: MenuItemType.LINK,
        },
        {
          label: 'Add user(s) to client(s)',
          link: routes.BACKOFFICE_ADD_USER_TO_ENVIRONMENT,
          type: MenuItemType.LINK,
        },
      ],
    },
  ];

  return (
    <SidebarLayoutWrapper>
      <BackofficeSidebarLayoutSidebar variant='permanent' open={isBackofficeSidebarOpen} sidebarOpen={isSidebarOpen}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={toggleBackofficeSidebar}>
            {isBackofficeSidebarOpen ? <IconDoubleArrowLeft size='small' /> : <IconDoubleArrowRight size='small' />}
          </IconButton>
        </Box>
        <SidebarMenu menuItems={primaryMenuItems} isSidebarOpen={isBackofficeSidebarOpen} />
      </BackofficeSidebarLayoutSidebar>
      <SidebarLayoutContent component='main' open={isBackofficeSidebarOpen}>
        {children}
      </SidebarLayoutContent>
    </SidebarLayoutWrapper>
  );
};
