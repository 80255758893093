import { Animation } from 'konva/lib/Animation';
import { IFrame } from 'konva/lib/types';
import { FrameInfo, TacticId, useOverlayGenerator } from 'overlay-generator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  useCurrentMatchTime,
  useCurrentPlaylistItem,
  useIsBuffering,
  useIsSeeking,
  useVideoPlayerActions,
  useVideoPlayerId,
  useVideoPlayerPlayingMode,
  useVideoPlayerRef,
} from '../';
import { FEATURE_FLAG } from '../../../../../api/user/use-fetch-feature-flags';
import { useFeatureFlag } from '../../../../contexts/app-state';

const areOverlaysReady = atomFamily<boolean, string>({
  key: 'overlays-ready',
  default: false,
});

const frameRate = atomFamily<number, string>({
  key: 'overlays-render-frame-rate',
  default: 0,
});

const frameInfo = atomFamily<FrameInfo, string>({
  key: 'overlays-frame-info',
  default: {
    frameNumber: 0,
    frameTactics: [],
    overlayElementsDebugInfo: [],
  },
});

export const useSetAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(areOverlaysReady(playerId));
};

export const useAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(areOverlaysReady(playerId));
};

export const useSetOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(frameInfo(playerId));
};

export const useOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameInfo(playerId));
};

export const useSetRenderFrameRate = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(frameRate(playerId));
};

export const useRenderFrameRate = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameRate(playerId));
};

export const useOverlaysController = () => {
  const videoPlayerRef = useVideoPlayerRef();
  const playlistItem = useCurrentPlaylistItem();
  const container = useRef<HTMLDivElement>(null);
  const { createAnimation, overlayGenerator, frameRate, frameInfo, isReady } = useOverlayGenerator({
    id: playlistItem.recordingId,
  });
  const lastRenderInfo = useRef<{
    width: number;
    height: number;
    tactics: TacticId[] | undefined;
    frame: number;
    showOverlays: boolean | undefined;
  }>({
    frame: 0,
    width: 0,
    height: 0,
    tactics: [],
    showOverlays: undefined,
  });
  const anim = useRef<{ controller: Animation | undefined }>({ controller: undefined });
  const matchTime = useCurrentMatchTime();
  const currentMatchTimeRef = useRef(0);
  const setAreOverlaysReady = useSetAreOverlaysReady();
  const isBuffering = useIsBuffering();
  const isSeeking = useIsSeeking();
  const setOverlaysFrameInfo = useSetOverlaysFrameInfo();
  const setRenderFrameRate = useSetRenderFrameRate();
  const actions = useVideoPlayerActions();
  const playingMode = useVideoPlayerPlayingMode();
  const [matrix3dTransformation, setMatrix3dTransformation] = useState<number[] | undefined>([]);

  useEffect(() => {
    if (!anim.current.controller) return;

    isSeeking || isBuffering ? anim.current.controller.stop() : anim.current.controller.start();
  }, [isSeeking, isBuffering]);

  useEffect(() => {
    isReady ? actions.resumeStandBy() : actions.handleStandBy();
    setAreOverlaysReady(isReady);
  }, [actions, setAreOverlaysReady, isReady]);

  useEffect(() => {
    currentMatchTimeRef.current = matchTime;
  }, [currentMatchTimeRef, matchTime]);

  useEffect(() => {
    setOverlaysFrameInfo(frameInfo);
  }, [setOverlaysFrameInfo, frameInfo]);

  const shouldUseObfuscationEndpoint = useFeatureFlag(FEATURE_FLAG.OBFUSCATE_HOMOGRAPHIES);

  const handleUpdateFrame = useCallback(
    async (frame: number, tactics: TacticId[] | undefined) => {
      if (!videoPlayerRef.current || !container.current) return;

      await overlayGenerator.drawFrameInCanvas(
        container.current,
        frame,
        {
          tactics,
        },
        shouldUseObfuscationEndpoint,
      );

      const matrix3d = overlayGenerator.getTransformationMatrix(frame, {
        width: videoPlayerRef.current.offsetWidth,
        height: videoPlayerRef.current.offsetHeight,
      });

      setMatrix3dTransformation(matrix3d);
      return Boolean(matrix3d);
    },
    [videoPlayerRef, overlayGenerator, shouldUseObfuscationEndpoint],
  );

  const handleVideoTimeUpdate = useCallback(
    (frame?: IFrame) => {
      if (!container.current || !videoPlayerRef.current) return;

      setRenderFrameRate(Math.round(frame?.frameRate ?? 0));
      const videoFrame = Math.round(currentMatchTimeRef.current * frameRate);

      const tactics =
        playlistItem.fundamentalsSelected && playlistItem.fundamentalsSelected.fundamentalsSelected[0] === 'all'
          ? undefined
          : (playlistItem.fundamentalsSelected.fundamentalsSelected as TacticId[]);

      const currentFrameInfo = {
        width: videoPlayerRef.current.offsetWidth,
        height: videoPlayerRef.current.offsetHeight,
        frame: videoFrame,
        tactics,
        showOverlays: playingMode.showOverlays,
      };

      if (JSON.stringify(currentFrameInfo) === JSON.stringify(lastRenderInfo.current) || !playingMode.showOverlays) {
        return false;
      } else {
        lastRenderInfo.current = currentFrameInfo;
        return handleUpdateFrame(videoFrame, tactics);
      }
    },
    [
      playingMode,
      container,
      frameRate,
      handleUpdateFrame,
      playlistItem.fundamentalsSelected,
      setRenderFrameRate,
      videoPlayerRef,
    ],
  );

  useEffect(() => {
    anim.current.controller = createAnimation(handleVideoTimeUpdate);
    const animationControls = anim.current.controller;

    animationControls.start();

    return () => {
      animationControls.stop();
    };
  }, [createAnimation, handleVideoTimeUpdate]);

  return { container, overlayGenerator, matrix3dTransformation };
};
