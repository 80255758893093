import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useQuery } from '@tanstack/react-query';
import { RefObject } from 'react';

import { dashboardGuestToken } from 'api/routes';
import { SUPERSET_URL } from 'config';

async function fetchGuestToken(dashboardId: string) {
  const response = await fetch(dashboardGuestToken(dashboardId), {
    method: 'GET',
  });
  const data = await response.json();
  return data.token;
}

const embedSupersetDashboard = async (dashboardId: string, container: HTMLElement) => {
  return embedDashboard({
    id: dashboardId,
    supersetDomain: SUPERSET_URL,
    mountPoint: container,
    fetchGuestToken: async () => fetchGuestToken(dashboardId),
    dashboardUiConfig: {
      hideTitle: true,
      hideTab: true,
      filters: {
        expanded: false,
      },
    },
  });
};

const fetchDashboard = async (dashboardId: string, containerRef: RefObject<HTMLDivElement>) => {
  try {
    return await embedSupersetDashboard(dashboardId, containerRef.current!);
  } catch (error) {
    throw new Error('Error loading dashboard');
  }
};

export const useEmbeddedDashboard = (dashboardId: string, containerRef: RefObject<HTMLDivElement>) => {
  return useQuery(['dashboard', dashboardId], () => fetchDashboard(dashboardId, containerRef), {
    retry: false,
  });
};
