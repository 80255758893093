import { Box, ClickAwayListener, ListItem, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { INITIAL_USERS_FILTERS, useKogniaUsers } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import ActiveFilters from 'shared/components/active-filters';
import { Autocomplete } from 'shared/components/autocomplete';
import { AutocompleteActiveFilter } from 'shared/components/autocomplete-multi-select/components/autocomplete-active-filter';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/components/autocomplete-dropdown-button';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/components/autocomplete-popper';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-popper-wrapper';
import { AutocompleteResult } from 'shared/components/autocomplete-multi-select/components/autocomplete-result';
import { AutocompleteResultsWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-results-wrapper';
import { AutocompleteTag } from 'shared/components/autocomplete-multi-select/components/autocomplete-tag';

interface Props {
  setKogniaUsersOnChange: (recordings: KogniaUser[]) => void;
  kogniaUsers: KogniaUser[];
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectUsers = ({ kogniaUsers, setKogniaUsersOnChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValues, setAutocompleteValues] = useState<KogniaUser[]>([]);
  const { items: kogniaUsersResults, isLoading, setFilters, fetchNextPage } = useKogniaUsers();

  const isOpen = Boolean(anchorEl);

  const close = useCallback(() => {
    setFilters(INITIAL_USERS_FILTERS);
    setAnchorEl(null);
  }, [setAnchorEl, setFilters]);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValues(kogniaUsers);
      setAnchorEl(event.currentTarget);
    },
    [setAutocompleteValues, kogniaUsers],
  );

  const handleAddClipsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleUpdateValue = useCallback(
    (newValues: KogniaUser[] | null) => {
      if (newValues === null) return;

      setAutocompleteValues(newValues);
    },
    [setAutocompleteValues],
  );

  const handleApply = useCallback(() => {
    setAutocompleteValues((autocompleteValues) => {
      setKogniaUsersOnChange(autocompleteValues);
      return autocompleteValues;
    });
    close();
  }, [setAutocompleteValues, setKogniaUsersOnChange, close]);

  const handleReset = useCallback(() => {
    setAutocompleteValues([]);
  }, [setAutocompleteValues]);

  const handleRemoveRecording = useCallback(
    (recordingId: string) => {
      setKogniaUsersOnChange(kogniaUsers.filter((value) => value.id !== recordingId));
    },
    [setKogniaUsersOnChange, kogniaUsers],
  );

  const handleRemoveAutocompleteRecording = useCallback(
    (recordingId: string) => {
      setAutocompleteValues(autocompleteValues.filter((value) => value.id !== recordingId));
    },
    [setAutocompleteValues, autocompleteValues],
  );

  const handleRenderItem = useCallback(
    (props: React.ComponentProps<typeof ListItem>, option: KogniaUser, { selected }: { selected: boolean }) => {
      return (
        <ListItem {...props} disablePadding>
          <AutocompleteResult
            autocompleteWidth={AUTOCOMPLETE_WIDTH}
            isChecked={selected}
            key={option.id}
            name={`${option.firstName} ${option.lastName} (${option.email})`}
          />
        </ListItem>
      );
    },
    [],
  );

  const handleSetFirstName = useCallback(
    (name: string) => {
      setFilters({ firstName: name });
    },
    [setFilters],
  );

  const renderTags = useCallback(
    (kogniaUsers: KogniaUser[]): React.ReactNode => {
      return (
        <AutocompleteTag>
          {kogniaUsers.map((kogniaUser) => {
            return (
              <AutocompleteActiveFilter
                id={kogniaUser.id}
                key={kogniaUser.id}
                name={`${kogniaUser.firstName} ${kogniaUser.lastName} (${kogniaUser.email})`}
                onRemove={handleRemoveAutocompleteRecording}
                size='small'
              />
            );
          })}
        </AutocompleteTag>
      );
    },
    [handleRemoveAutocompleteRecording],
  );

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <AutocompleteResultsWrapper {...props} onReset={handleReset} onApply={handleApply}>
        {props.children}
      </AutocompleteResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body1'}>Select users</Typography>
      <Box>
        <ActiveFilters>
          <AutocompleteDropdownButton
            isOpen={isOpen}
            items={kogniaUsers}
            label={kogniaUsers.length > 0 ? `${kogniaUsers.length} User(s)` : 'Users'}
            onClick={handleAddClipsClick}
          />
          {anchorEl && (
            <ClickAwayListener onClickAway={handleApply}>
              <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
                <AutocompletePopperContent elevation={8}>
                  <Autocomplete
                    autoFocus
                    PaperComponent={paperComponent}
                    PopperComponent={AutocompletePopperWrapper}
                    fetchNextPage={fetchNextPage}
                    getItemLabel={(kogniaUser) =>
                      `${kogniaUser.firstName} ${kogniaUser.lastName} (${kogniaUser.email})`
                    }
                    inputWidth={AUTOCOMPLETE_WIDTH}
                    isLoading={isLoading}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    listWidth={AUTOCOMPLETE_WIDTH}
                    multiple={true}
                    onChange={handleSetFirstName}
                    open
                    options={kogniaUsersResults}
                    renderOption={handleRenderItem}
                    renderTags={renderTags}
                    resultsHeight={310}
                    resultsNoMatches={'No users found'}
                    updateValue={handleUpdateValue}
                    value={autocompleteValues}
                  />
                </AutocompletePopperContent>
              </AutocompletePopper>
            </ClickAwayListener>
          )}
          {kogniaUsers &&
            kogniaUsers.map((kogniaUser) => (
              <AutocompleteActiveFilter
                id={kogniaUser.id}
                key={kogniaUser.id}
                name={`${kogniaUser.firstName} ${kogniaUser.lastName} (${kogniaUser.email})`}
                onRemove={handleRemoveRecording}
              />
            ))}
        </ActiveFilters>
      </Box>
    </Stack>
  );
};
