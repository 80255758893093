import { Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';

import { EmbeddedDashboard } from 'features/dashboard/EmbeddedDashboard';

export const SelectDashboardWidget = () => {
  const [dashboardId, setDashboardId] = useState<string>('');
  const [inputDashboardId, setInputDashboardId] = useState<string>('');

  return (
    <Stack height={'100%'}>
      <Stack direction={'row'} alignItems={'center'} gap={2} sx={{ padding: 2, height: 100 }}>
        <TextField
          size={'small'}
          value={inputDashboardId}
          onChange={(e) => setInputDashboardId(e.target.value)}
          sx={{ width: '380px' }}
        />
        <Button color={'primary'} variant={'contained'} size={'large'} onClick={() => setDashboardId(inputDashboardId)}>
          Select Dashboard
        </Button>
      </Stack>
      {dashboardId && <EmbeddedDashboard key={dashboardId} dashboardId={dashboardId} />}
    </Stack>
  );
};
