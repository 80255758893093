const {
  MODE: ENVIRONMENT,
  VITE_REACT_APP_RELEASE_VERSION,
  VITE_SUPERSET_URL,
  PUBLIC_URL,
  DEV,
  PROD,
  BASE_URL,
} = import.meta.env;

export { ENVIRONMENT, VITE_REACT_APP_RELEASE_VERSION, PUBLIC_URL, DEV, PROD, BASE_URL, VITE_SUPERSET_URL };
